// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/* eslint-disable */
function legendFilter() {
  const legendItems = $('.js-location-legend-item');
  const markers = $('.map .marker');
  const locationDescriptionItems = $('.js-location-desc-list-item');
  let isAnimating = false;

  legendItems.on('click', function () {
    const item = $(this);
    const color = item.attr('data-color');
    if (!item.hasClass('active')) {
      legendItems.removeClass('active');
      item.addClass('active');
      if (color) {
        const matches = locationDescriptionItems.filter(
          (index, element) => color === $(element).attr('data-color')
        );
        const filteredMarkers = [];
        matches.each((index, element) => {
          const match = $(element);
          const id = match.closest('.marker-info').attr('id');
          const marker = $(`.marker[data-target="${id}"]`);
          if (marker.length) {
            filteredMarkers.push(marker.get(0));
          }
        });

        markers.each((index, element) => {
          const $element = $(element);
          if (!filteredMarkers.includes(element)) {
            $element.fadeOut(200).removeClass('shown').addClass('hidden');
          } else {
            $element.fadeIn(200).removeClass('hidden').addClass('shown');
          }
        });
      }
    } else {
      item.removeClass('active');
      markers.fadeIn(200).removeClass('hidden').addClass('shown');
    }
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  legendFilter();
  $('.marker').on('click', function (event) {
    // Prevent the body click handler from triggering
    event.stopPropagation();

    // Remove the 'active' class from all marker-icons
    $('.marker .marker-icon').removeClass('active-marker');

    // Add the 'active-marker' class to the clicked marker's icon
    $(this).find('.marker-icon').addClass('active-marker');

    // Get the data-target attribute
    var targetId = $(this).data('target');

    // Remove the 'active' class from all other marker-info elements
    $('.marker-info')
      .not('#' + targetId)
      .removeClass('active');

    // Toggle the 'active' class on the corresponding marker-info element
    $('#' + targetId).toggleClass('active');
  });

  // Handle body click to remove active classes
  $(document).on('click', function () {
    // Remove the 'active' class from all marker-icons
    $('.marker .marker-icon').removeClass('active-marker');

    // Remove the 'active' class from all marker-info elements
    $('.marker-info.active').removeClass('active');
  });
  $('.menu-item a').on('click', function () {
    let url = $(this).attr('href');
    var id = url && url.indexOf('#') >= 0 ? url.split('#')[1] : null;
    if (id) {
      var activeTab = $('[id^="' + id + '"]');
      activeTab.each(function () {
        $(this).trigger('click');
      });
    }
  });

  $('button.menu-icon').on('click', function () {
    $('.top-bar').toggleClass('active-bar');
    $('body').toggleClass('locked');
    $('html').toggleClass('locked');
  });

  const optionsImage = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.testimonials-slider-content',
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 2000,
  };
  const optionsContent = {
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.testimonials-slider-image',
    dots: true,
    arrows: true,
    focusOnSelect: true,
    centerMode: false,
    speed: 2000,
    fade: true,
  };
  $('.testimonials-slider-image').slick(optionsImage);
  $('.testimonials-slider-content').slick(optionsContent);
  var hash = window.location.hash.substring(1); //get the string after the hash
  if (hash == 'acquirer-label') {
    $('.second-tab a').trigger('click');
  }
  if (hash == 'leadership-label') {
    $('.third-tab a').trigger('click');
  }
  $(document).on('click.zf.tabs', '.tabs-title', function (e) {
    const id = e.target.getAttribute('data-slider');
    if (id === 'reinit') {
      $('.testimonials-slider-image').slick('unslick').slick(optionsImage);
      $('.testimonials-slider-content').slick('unslick').slick(optionsContent);
    }
  });

  $('.latest-slider-image').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.latest-slider-content',
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 2000,
  });
  $('.latest-slider-content').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.latest-slider-image',
    dots: true,
    arrows: true,
    focusOnSelect: true,
    centerMode: false,
    speed: 2000,
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }
  $('.js-more').click(function () {
    // console.log($(this).parent().find('.js-leadership'));
    let el = $(this).parent().find('.js-leadership');
    let buttom = $('.js-more');
    let curHeight = el.height();
    let autoHeight = el.css('height', 'auto').height();
    if (el.hasClass('is-active')) {
      el.height(curHeight).animate({ height: '6.6em' }, 1000);
      el.removeClass('is-active');
      buttom.removeClass('show');
    } else {
      el.height(curHeight).animate({ height: autoHeight }, 1000);
      el.addClass('is-active');
      buttom.addClass('show');
    }
  });
  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
